const ERROR_MESSAGES = {
  'AKUN KAMU TERDAFTAR MELALUI LOGIN DENGAN GOOGLE': 'Mohon maaf, akun kamu terdaftar melalui Google. Silahkan lakukan login ulang menggunakan metode login dengan Google',
  'USER NOT FOUND': 'Mohon maaf, email yang kamu masukkan tidak terdaftar. Silahkan coba lagi dengan email yang sudah terdaftar.',
  'PASSWORD IS WRONG': 'Mohon maaf, sepertinya terdapat kesalahan data. Mohon untuk mengecek ulang data anda.',
  'WRONG_OTP': 'Mohon maaf, kode OTP yang kamu masukkan salah. Silahkan coba lagi.',
  'ERR_NO_PRODUCT_SERVICE_ID': 'Product not found!',
  'ERR_NO_TRANSACTION_ID': 'Transaction id not found!',
  'ERR_TRANSACTION_PENDING': 'Transaction is pending!',
}

export function parseErrorCatch(e) {
  const errorMessage = e.response?.data?.message || e?.message || e?.data?.message || ''
  const errorMessageParsed = ERROR_MESSAGES[errorMessage.toUpperCase()]
  if (!errorMessageParsed) return errorMessage
  return errorMessageParsed
}